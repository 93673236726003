const order_bot = {
  buffer: 'Scorta',
  buffer_donators: 'Scorta donatori',
  buffer_forecast_title: 'Impostazioni base per il calcolo del fabbisogno',
  buffer_receptors: 'Scorta riceventi',
  buffer_warehouses: 'Scorta depositi',
  buffer_tooltip:
    'Inserisci un tempo diverso di scorta per i depositi donatori e quelli riceventi',
  buffers_options: {
    0: 'Nessuna',
    30: '30 giorni',
    45: '45 giorni',
    60: '60 giorni',
    75: '75 giorni',
    90: '90 giorni',
    105: '105 giorni',
    120: '120 giorni',
    150: '150 giorni',
    180: '180 giorni',
    360: '360 giorni',
  },
  cannot_submit_message:
    'Selezionare almeno un "Produttore" o una "Classe" per proseguire',
  clarios_products_groups: {
    U: 'Auto',
    F: 'Auto EFB',
    G: 'Auto AGM',
    T: 'Truck',
    L: 'Professional',
    M: 'Optima',
    X: 'Altro',
  },
  class_merch_or_manufacturer: 'Produttore/i o classe/i',
  compose: {
    title: 'Configurazione',
  },
  cpa: 'CPA',
  cpa_reference: 'Cpa di riferimento',
  cpa_tooltip: 'CPA periodo selezionato',
  customer_ordered: 'Ord. Clienti',
  customer_ordered_tooltip: 'Ordini cliente',
  destinations: 'Destinazioni',
  discount_extra: 'Extra sconto globale',
  discounts_classes_merch: 'Extra sconto per le classi selezionate',
  fab_x: {
    enable: 'Attiva il filtro',
    from_tolerance: 'Tolleranza',
    from_warehouse_number: 'Almeno in %{count} su %{selected} selezionati',
    title: 'Filtro Fab X',
    types_enabled: 'Tipologie abilitate',
    warehouses_reference: 'Magazzini di riferimento',
  },
  use_five_thresholds: "Utilizza l'algoritmo delle 5 soglie",
  include_new: 'Attiva i trasferimenti per i nuovi prodotti',
  five_thresholds: {
    logistics_classes: 'Classi logistiche',
    use_eoq: "Utilizza l'EOQ per ordinare",
    use_ordered: 'Considera gli ordini fornitore nella disponibilità logistica',
    centralized_orders_filter: 'Attiva il filtro ordini centralizzati',
    ordered_days_tolerance: 'Tollenzanda in giorni per la scadenza +/-',
    receptors: 'Classi logistiche abilitate al calcolo del fabbisogno',
    donators: 'Classi logistiche abilitate alla donazione',
    cdv_range: 'Includi se CdV compreso tra',
    cdv_range_min: 'Includi se CdV maggiore di',
    cdv_range_add_max: 'imposta un limite massimo',
    max_lt_days: 'Ignora se lead time maggiore di (giorni)',
    cpa_reference:
      "Cpa di riferimento per l'assegnazione della classe logistica",
    include_new: 'Analizza anche i nuovi prodotti',
    s_multipliers: 'Ricarica/diminuisci i valori di S in percentuale',
    s_multiplier: 'Percentuale ⁺∕₋',
  },
  orders_destinations: 'Modifica i depositi di destinazione degli ordini',
  orders_destination: 'Invia gli ordini al deposito di',
  from_warehouses: 'In arrivo da depositi',
  ignore_ta: 'Ignora i tempi di approvvigionamento',
  ignore_customers_orders: 'Ignora gli ordini clienti',
  low_move_buffer: 'Filtro bassa movimentazione',
  min_buffer: 'Scorta minima',
  moved_type: 'Mostra se',
  moved_type_options: {
    moved: 'Ordinare e/o trasferire',
    ordered: 'Solo se da ordinare',
    transfered: 'Solo se da trasferire',
    all: 'Tutti i prodotti',
  },
  origins: 'Partenze',
  order_package: 'Ordina in base a',
  ordered: 'Ord. fornitori',
  ordeded_tooltip: 'Ordini fornitore',
  overstock: 'Overstock',
  package_size_field: 'Campo di riferimento',
  package_round_ceil_tolerance: 'Tolleranza arrotondamento per eccesso',
  package_default_round_ceil_tolerance:
    'Tolleranza arrotondamento "Confezione"',
  preparation_from_warehouses: 'In prepar. da depositi',
  preparation_from_warehouses_tooltip: 'In preparazione da depositi',
  quantity: 'Esistenza',
  quantity_tooltip: 'Esistenza (Giacenza + Indisponibile)',
  routine: {
    selected: 'Routine selezionata',
  },
  session_empty: 'Non ci sono sessioni di lavoro salvate',
  simulate: 'Simula',
  simulate_tooltip: 'Avvia la simulazione con i filtri impostati',
  simulate_sync: 'Aggiorna i depositi e poi avvia la simulazione',
  steps: {
    general: 'Generale',
    logistics_classes: 'Classi logistiche',
    stock: 'Scorte',
    five_thresholds: '5 Soglie',
    speculative_orders: 'Ordini speculativi',
    warehouses: 'Magazzini',
    shuttles: 'Navette',
    fab_x: 'Filtro Fab X',
    view: 'Visualizzazione',
    refines: 'Perfeziona',
    discounts: 'Sconti',
  },
  transfer_package: 'Trasferisci in base a',
  type_d_as_c: 'Tratta i Tipi D come C',
  unavailability: 'Indisponibile',
  view_products: 'Prodotti da visualizzare',
  view_show_added_from: 'Mostra aggiunti da',
  view_cpa: 'Seleziona le CPA da visualizzare',
  view_table_format: 'Formattazione tabella',
  view_cpa_hint: 'Specifica le CPA da visualizzare, default: "%{default}"',
  virtual: {
    ignore_qtas: 'Ignora le giacenze',
    ignore_qtas_info: 'Ignora le giacenze, utilizza solamente le cpa e le date',
    references: 'Utilizza i magazzini',
  },
  warehouse_sort_field: 'Ordina il magazzino per',
  zero_moving: 'Zero moving',
  zero_moving_options: {
    0: 'Nessuno',
    360: '12 mesi',
    540: '18 mesi',
    720: '24 mesi',
  },
}

export default order_bot
